import React, { useState, useEffect } from 'react';
import './index.css';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { useNavigate } from "react-router-dom";
import { AiOutlineMenu, AiOutlineHome, AiOutlineLogout, AiOutlineDown, AiOutlineLeft, AiFillExclamationCircle, AiOutlinePlus } from "react-icons/ai";
import GetDosesService from '../../services/GetDosesService';
import Divider from "@material-ui/core/Divider";
import { Tab, Tabs, AppBar, Typography, Box, Modal } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Calendar from 'react-calendar';
import styled from 'styled-components';
import logo from '../../assets/logo-small.png';
import GetDoseService from '../../services/GetDoseService';
import Dose from '../../components/Dose';
import DeleteMedicineService from '../../services/DeleteMedicineService';

function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
  );
}

function App() {

  type ValuePiece = Date | null;

  type Value = ValuePiece | [ValuePiece, ValuePiece];

  const [isOpen, setIsOpen] = useState(false);
  const [medicines, setMedicines] = useState([]);
  const [medicinesToday, setMedicinesToday] = useState([]);
  const [medicinesDay, setMedicinesDay] = useState([]);
  const [success, setSuccess] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [medicineId, setMedicineId] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const mark = [
    '01/10/2023',
    '27/09/2023',
    '22/09/2023'
  ];

  const [valueCalendar, onChangeCalendar] = useState<Value>(new Date());

  const navigate = useNavigate();

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  const goBack = () => {
    navigate(-1);
  }

  const logout = () => {
    localStorage.removeItem('token');
    navigate('/');
  }

  const goToHome = () => {
    navigate('/home');
  }

  const goToAddMedicine = () => {
    navigate('/add-medicine');
  }

  const delay = (delayInms: number) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  };

  const removeMedicine = () => {
    setIsLoading(true);
    setIsDeleteOpen(false);
    {
      medicineId && DeleteMedicineService(medicineId).then(async () => {
        setIsLoading(false);
        setSuccess("Medicamento removido com sucesso!");
        await delay(3000);
        setSuccess(undefined);
        window.location.reload();
      }).catch(async error => {
        setIsLoading(false);
        setError("Não conseguimos remover seu medicamento!");
        await delay(3000);
        setError(undefined);
      })
    };
  }

  const formatDate = (date: Date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
  }

  useEffect(() => {
    const token = (localStorage.getItem('token') as string);
    if (!token) {
      navigate('/');
      return
    }

    const medicineToParse = localStorage.getItem('doses');
    localStorage.setItem('medicineToEdit', '');
    if (medicineToParse) {
      const medicine = JSON.parse(medicineToParse);
      setMedicines(medicine);
    }

  }, [])

  useEffect(() => {

    setIsLoading(true);

    GetDoseService(formatDate(new Date()));

    GetDosesService().finally(() => {
      const medicineToParse = JSON.parse(localStorage.getItem('doses') || "[]");
      const doseToParse = JSON.parse(localStorage.getItem('doses-today') || "[]");

      const medicineWithDose = medicineToParse.map((medicine: any) => {

        const doses = doseToParse.filter((dose: any) => (medicine.id === parseInt(dose.medicineid)))
        return {
          ...medicine,
          doses
        }
      });
      setMedicinesToday(medicineWithDose);
      setMedicinesDay(medicineWithDose);
      setIsLoading(false);
    });
  }, [])

  useEffect(() => {

    GetDoseService(formatDate(valueCalendar as Date));

    GetDosesService().then(() => {
      const medicineToParse = JSON.parse(localStorage.getItem('doses') || "[]");
      const doseToParse = JSON.parse(localStorage.getItem('doses-today') || "[]");

      const medicineWithDose = medicineToParse.map((medicine: any) => {

        const doses = doseToParse.filter((dose: any) => (medicine.id === parseInt(dose.medicineid)))
        return {
          ...medicine,
          doses
        }
      });
      setMedicinesDay(medicineWithDose);
    });

  }, [valueCalendar]);

  const [value, setValue] = React.useState("one");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  const MedicineTabs = withStyles({
    root: {
      backgroundColor: 'transparent',
      color: 'gray',
    },
    indicator: {
      backgroundColor: '#B0DECF',
    },
  })(Tabs);

  const MedicineAppBar = withStyles({
    root: {
      backgroundColor: 'white',
      color: 'gray',
      zIndex: 'unset'
    },
    indicator: {
      backgroundColor: '#B0DECF',
    },
  })(AppBar);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  const styles = {
    root: {
      width: '100%',
      padding: '0px 10px 10px 10px'
    }
  };

  const MedicineBox = withStyles(styles)(Box);

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`a11y-tabpanel-${index}`}
        className="Medicine-reminder-tab-content"
        aria-labelledby={`a11y-tab-${index}`}
        {...other}
      >
        {value === index && (
          <MedicineBox >
            <Typography>{children}</Typography>
          </MedicineBox>
        )}
      </div>
    );
  }

  const CalendarContainer = styled.div`
        margin: auto;
        margin-top: 20px;
        padding: 10px;
        border-radius: 3px;
        
        .react-calendar__navigation {
            display: flex;
        
            .react-calendar__navigation__label {
              font-weight: bold;
            }
        
            .react-calendar__navigation__arrow {
              flex-grow: 0.333;
            }
          }

        .react-calendar__month-view__weekdays {
            text-align: center;
            color: gray;
          }
        
        button {
            margin: 0px;
            background-color: #F6F6F6;
            border: 0;
            border-radius: 3px;
            color: #10DB9C;
            font-weight: 800;
            padding: 5px 0;

        
            &:hover {
              color: #D3D5D9;
            }
        
            &:active {
                color: #F6F6F6;
              background-color: #10DB9C;
            }
          }

        /* ~~~ day grid styles ~~~ */
        /* ... */

        /* ~~~ neighboring month & weekend styles ~~~ */
        .react-calendar__month-view__days__day--neighboringMonth {
            opacity: 0.7;
        }
        .react-calendar__month-view__days__day--weekend {
            color: #10DB9C;
        }

        .react-calendar__tile--range {
            color: #F6F6F6;
            background-color: #10DB9C;
            box-shadow: 0 0 6px 2px rgba(0,0,0,0.3);
        }
        `;

  return (
    <div className="Medicine-reminders">
      {/* <div className="Home-header-container">
        <div className="Medicine-reminder-text">Medicamentos</div> */}
      {/* </div>  */}
      {isLoading && <LoadingSpinner />}
      <div className="Add_medicine-header">
        <div className="Add_medicine-header-container">
          <div>
            <AiOutlineLeft className='Home-icon' onClick={goBack} />
            <img src={logo} className="Add_medicine-logo" alt="logo" />
          </div>
          <AiOutlineMenu className='Add_medicine-icon' onClick={toggleDrawer} />

        </div>
        <div className="Medicine-title-text">Medicamento</div>
      </div>
      <header className="Reminder-medicine-header">
        <MedicineAppBar className="Reminder-medicine-link" position="static" >
          <MedicineTabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            aria-label="wrapped label tabs example"
          >
            <Tab value="one" label="Lista" />
            <Tab value="two" label="Calendário" />
          </MedicineTabs>
        </MedicineAppBar>
        <TabPanel value={value} index="one">
          <div className='Medicine-add-detail-container' onClick={() => goToAddMedicine()}>
            <div className='Medicine-item-container' >
              <p className='Medicine-name'>Adicionar novo medicamento</p>
              <AiOutlinePlus className='Medicine-details-icon-plus' />
            </div>
          </div>
          <p className='Medicine-reminder-title-text'>Remédios de hoje {String(new Date().getDay()).padStart(2, '0')}/{String(new Date().getMonth() + 1).padStart(2, '0')}/{new Date().getFullYear()}</p>

          {medicinesToday && medicinesToday.length > 0 && medicinesToday.map((medicine: any) => <Dose setIsLoading={setIsLoading} setMedicineId={setMedicineId} setIsDeleteOpen={setIsDeleteOpen} medicine={medicine} />)}
        </TabPanel>
        <TabPanel value={value} index="two">
          <CalendarContainer>
            <Calendar
              onChange={onChangeCalendar}
              value={valueCalendar}
              tileClassName={({ date, view }) => {
                if (mark.find(x => x === date.toLocaleDateString("pt-BR"))) {
                  return 'highlight'; // your class name
                }
              }}
            />
          </CalendarContainer>
          <div className='Medicine-add-detail-container' onClick={() => goToAddMedicine()}>
            <div className='Medicine-item-container' >
              <p className='Medicine-name'>Adicionar novo medicamento</p>
              <AiOutlinePlus className='Medicine-details-icon-plus' />
            </div>
          </div>
          {medicinesDay && medicinesDay.length > 0 && medicinesDay.map((medicine: any) => <Dose setIsLoading={setIsLoading} setMedicineId={setMedicineId} setIsDeleteOpen={setIsDeleteOpen} medicine={medicine} />)}
        </TabPanel>
      </header>
      <Drawer open={isOpen} onClose={toggleDrawer} direction='right'>
        <div className="Home-drawer-link" onClick={goToHome} >
          <AiOutlineHome color='gray' size={20} />
          <p className='Home-drawer-link-text'>Home</p>
        </div>
        <Divider />
        <div className="Home-drawer-link" onClick={logout} >
          <AiOutlineLogout color='gray' size={18} />
          <p className='Home-drawer-link-text'>Sair</p>
        </div>
        <Divider />
      </Drawer>
      {isDeleteOpen && <Modal open>
        <div className='modal-container'>
          <h2>Tem certeza que quer deletar o medicamento?</h2>
          <button style={{ marginRight: '10px' }} onClick={removeMedicine}>
            Confirmar
          </button>
          <button className="toggle-button" onClick={() => setIsDeleteOpen(false)}>
            Cancelar
          </button>
        </div>
      </Modal>}
      {success &&
        <Modal open>
          <div className='modal-container-success'>
            <h2>{success}</h2>
          </div>
        </Modal>
      }
      {error &&
        <Modal open>
          <div className='modal-container-error'>
            <h2>{error}</h2>
          </div>
        </Modal>
      }
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { Button, Card, Input, List, Modal, notification } from 'antd';
import { AiOutlineLeft } from 'react-icons/ai';
import { EyeOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import logo from '../../assets/logo-small.png';
import './index.css';
import GetUserByEmailService from '../../services/GetUserByEmail';
import GetUserByPhoneService from '../../services/GetUserByPhone';
import SendFriendInvite from '../../services/SendFriendInvite';
import GetListFriendship from '../../services/GetListFriendship';
import ChangeFriendStatus from '../../services/ChangeFriendStatus';

interface FriendDataType {
  id: string;
  name: string;
  email: string;
  status: string;
  phone: string;
  person1id: string;
  person2id: string;
}

const ManageFriends = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addNewFriendModal, setAddNewFriendModal] = useState(false);
  const [confirmAddFriendModal, setConfirmAddFriendModal] = useState(false);
  const [inviteViaWhatsAppModal, setInviteViaWhatsAppModal] = useState(false);
  const [inviteViaEmailAppModal, setInviteViaEmailAppModal] = useState(false);
  const [removeFriend, setRemoveFriend] = useState(false);
  const [cancelFriendSended, setCancelFriendSended] = useState(false);
  const [cancelFriendReceived, setCancelFriendReceived] = useState(false);
  const [acceptFriendReceived, setAcceptFriendReceived] = useState(false);
  const [getEmailOrPhone, setGetEmailOrPhone] = useState('');
  const [friendData, setFriendData] = useState<FriendDataType | null>(null);
  const [friendId, setFriendId] = useState('');
  const [friendship, setFriendship] = useState<FriendDataType[]>([]);
  const userId = JSON.parse(localStorage.getItem('userId') || '');

  const fetchFriendship = async () => {
    setLoading(true);
    try {
      await GetListFriendship();
      const updatedFriendship = JSON.parse(localStorage.getItem('friends') || '[]');
      setFriendship(updatedFriendship);
    } catch (error) {
      console.error('Erro ao buscar a lista de amizades:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFriendship();
  }, []);

  const showModal = () => {
    setOpen(true);
    setRemoveFriend(true);
    setCancelFriendSended(true);
    setCancelFriendReceived(true);
  };

  const hideModal = () => {
    setOpen(false);
    setRemoveFriend(false);
    setCancelFriendSended(false);
    setCancelFriendReceived(false);
    setAcceptFriendReceived(false);
    setInviteViaWhatsAppModal(false);
    setInviteViaEmailAppModal(false)
  };

  const onClickGetUser = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmail = emailRegex.test(getEmailOrPhone);
    setLoading(true);
    try {
      let userData;
      if (isEmail) {
        await GetUserByEmailService(getEmailOrPhone);
        userData = JSON.parse(localStorage.getItem('user-by-email') || 'null');
      } else {
        await GetUserByPhoneService(getEmailOrPhone);
        userData = JSON.parse(localStorage.getItem('user-by-phone') || 'null');
      }

      if (userData === null) {
        if (isEmail) {
          setInviteViaEmailAppModal(true)
        } else {
          setInviteViaWhatsAppModal(true);
        }
      } else {
        setFriendData(userData);
        setConfirmAddFriendModal(true);
      }
    } catch (error) {
      console.error("Falha ao obter dados do usuário", error);
      notification.error({ message: 'Erro ao buscar usuário.' });
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (userId: string, friendId: string) => {
    try {
      await SendFriendInvite(userId, friendId, true, true);
      notification.success({ message: 'Pedido de amizade enviado com sucesso.' });
      await fetchFriendship();
    } catch (error) {
      notification.error({ message: 'Não foi possível enviar o pedido de amizade.' });
    }
  };

  const onCancelFriendship = async (id: string, status: string) => {
    try {
      await ChangeFriendStatus(id, status);
      notification.success({ message: 'Amizade removida com sucesso.' });
      await fetchFriendship();
    } catch (error) {
      notification.error({ message: 'Não foi possível remover essa amizade.' });
    }
  };

  const onAcceptFriendship = async (id: string, status: string) => {
    try {
      await ChangeFriendStatus(id, status);
      notification.success({ message: 'Amizade feita com sucesso.' });
      await fetchFriendship();
    } catch (error) {
      notification.error({ message: 'Não foi possível aceitar essa amizade.' });
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGetEmailOrPhone(e.target.value);
  };

  const friends = friendship.filter((friend: FriendDataType) => friend.status === 'ACCEPTED');
  const sentRequests = friendship.filter((request: FriendDataType) => (request.status === 'PENDING' && request.person2id !== userId.toString()));
  const receivedRequests = friendship.filter((request: FriendDataType) => (request.status === 'PENDING' && request.person2id === userId.toString()));

  return (
    <div className="manage-friend">
      <div className="friend-screen">
        <AiOutlineLeft className="Home-icon" onClick={goBack} />
        <img src={logo} className="Add_medicine-logo" alt="logo" />
      </div>
      <h2>
        <div className="Medicine-title-text">Gerenciar conexões</div>
      </h2>
      {friends.length > 0 && (
        <Card title="Minhas conexões" className="card my-friends">
          <List
            dataSource={friends}
            renderItem={(friend: FriendDataType) => (
              <List.Item
                className="friend-list"
                actions={[
                  <div key="actions">
                    <Button
                      className="small-button"
                      type="primary"
                      onClick={() => {
                        const friendClicked = friends.find((friendClicked: FriendDataType) => friendClicked.email === friend.email);
                        if (friendClicked) {
                          setFriendId(friendClicked.id);
                          setOpen(true);
                        }
                      }}
                    >
                      <CloseOutlined />
                    </Button>
                    <Button
                      className="see-medicine-button"
                      type="primary"
                      onClick={() => navigate(`/medicine-friend-table/${(friend.person1id !== userId.toString() ? friend.person1id : friend.person2id)}/${friend.name}`)}
                    >
                      <EyeOutlined />
                    </Button>
                  </div>,
                ]}
              >
                <List.Item.Meta
                  title={<span className="list-item-title">{friend.name}</span>}
                  description={<span>{friend.email}</span>}
                />
              </List.Item>
            )}
          />
        </Card>
      )}
      {sentRequests.length > 0 && (
        <Card title="Solicitações enviadas" className="card requests-sent">
          <List
            dataSource={sentRequests}
            renderItem={(request: FriendDataType) => (
              <List.Item
                key={request.id}
                actions={[
                  <Button
                    key="cancel"
                    className="small-button"
                    type="primary"
                    onClick={() => {
                      const requestClicked = sentRequests.find((requestClicked: FriendDataType) => requestClicked.email === request.email);
                      if (requestClicked) {
                        setFriendId(requestClicked.id);
                        setCancelFriendSended(true);
                      }
                    }}
                  >
                    <CloseOutlined />
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={<span className="list-item-title">{request.name}</span>}
                  description={<span>{request.email}</span>}
                />
              </List.Item>
            )}
          />
        </Card>
      )}

      {receivedRequests.length > 0 && (
        <Card title="Solicitações recebidas" className="card requests-received">
          <List
            dataSource={receivedRequests}
            renderItem={(request: FriendDataType) => (
              <List.Item
                key={request.email}
                actions={[
                  <div key="actions">
                    <Button
                      className="small-button"
                      type="primary"
                      onClick={() => {
                        const requestClicked = receivedRequests.find((requestClicked: FriendDataType) => requestClicked.email === request.email);
                        if (requestClicked) {
                          setFriendId(requestClicked.id);
                          setCancelFriendReceived(true);
                        }
                      }}
                    >
                      <CloseOutlined />
                    </Button>
                    <Button
                      className="see-medicine-button"
                      type="primary"
                      onClick={() => {
                        const requestClicked = receivedRequests.find((requestClicked: FriendDataType) => requestClicked.email === request.email);
                        if (requestClicked) {
                          setFriendId(requestClicked.id);
                          setAcceptFriendReceived(true)
                        }
                      }
                      }
                    >
                      <CheckOutlined />
                    </Button>
                  </div>,
                ]}
              >
                <List.Item.Meta
                  title={<span className="list-item-title">{request.name}</span>}
                  description={<span>{request.email}</span>}
                />
              </List.Item>
            )}
          />
        </Card>
      )}

      {friends.length === 0 && sentRequests.length === 0 && receivedRequests.length === 0 && (
        <div style={{ color: '#8b8884', fontWeight: '500', fontSize: '16px', textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
          <span>Nenhuma conexão encontrada</span>
        </div>
      )}

      <Button
        type="primary"
        className="add-new-friend"
        onClick={() => setAddNewFriendModal(true)}
      >
        Adicionar nova conexão
      </Button>
      <br />
      <Modal
        title={
          <>
            {open && <span>Confirmar remoção</span>}
            {cancelFriendSended && <span>Cancelar solicitação</span>}
            {cancelFriendReceived && <span>Remover solicitação recebida</span>}
            {acceptFriendReceived && <span>Aceitar solicitação recebida</span>}
          </>
        }
        visible={open || cancelFriendSended || cancelFriendReceived || acceptFriendReceived}
        onOk={() => {
          console.log('clicou!');
        }}
        onCancel={hideModal}
        footer={[
          <Button key="back" onClick={hideModal}>
            Não
          </Button>,
          <Button
            key="submit"
            type="primary"
            className="button-confirm"
            onClick={() => {
              if (open || cancelFriendSended) {
                onCancelFriendship(friendId, 'REFUSE');
              }
              if (cancelFriendReceived) {
                onCancelFriendship(friendId, 'REFUSE');
              }
              if (acceptFriendReceived) {
                onAcceptFriendship(friendId, 'ACCEPTED');
              }
              hideModal();
            }}
          >
            Sim
          </Button>,
        ]}
      >
        {open && <span>Você tem certeza que deseja remover esta conexão?</span>}
        {cancelFriendSended && (
          <span>Você tem certeza que deseja cancelar essa solicitação enviada?</span>
        )}
        {cancelFriendReceived && (
          <span>Você tem certeza que deseja remover está solicitação recebida?</span>
        )}
        {acceptFriendReceived && (
          <span>Você deseja aceitar essa solicitação de amizade recebida?</span>
        )}
      </Modal>
      <Modal
        visible={addNewFriendModal}
        title="Adicionar nova conexão"
        onOk={onClickGetUser}
        onCancel={() => setAddNewFriendModal(false)}
        footer={[
          <Button key="back" onClick={() => setAddNewFriendModal(false)}>
            Fechar
          </Button>,
          <Button
            key="submit"
            type="primary"
            className="button-confirm"
            onClick={onClickGetUser}
          >
            Adicionar
          </Button>,
        ]}
      >
        <span>Compartilhar os meus medicamentos com essa conexão</span>
        <br />
        <br />
        <Input
          placeholder="Digite o e-mail ou telefone"
          value={getEmailOrPhone}
          onChange={handleInputChange}
        />
      </Modal>
      <Modal
        title="Usuário não encontrado"
        visible={inviteViaWhatsAppModal}
        footer={[
          <Button key="back" onClick={() => setInviteViaWhatsAppModal(false)}>
            Não
          </Button>,
          <Button
            key="submit"
            type="primary"
            className="button-confirm"
            onClick={() => {
              const whatsappLink = `https://wa.me/+55${getEmailOrPhone.replace(/\D/g, '')}?text=Estou%20usando%20o%20app%20Soul%20Prosa%C3%BAde%20para%20gerenciar%20os%20meus%20medicamentos%20e%20da%20minha%20fam%C3%ADlia.%20Baixe%20agora%20e%20experimente!%20https://app.prosaude.com.br`;
              window.open(whatsappLink, '_blank');
              setInviteViaWhatsAppModal(false);
            }}
          >
            Sim
          </Button>,
        ]}
        confirmLoading={loading}
        onCancel={() => setInviteViaWhatsAppModal(false)}
      >
        <div>
          O número que você digitou não foi encontrado em nosso sistema.
          <br />
          <br />
          Deseja enviar um convite via WhatsApp?
        </div>
      </Modal>
      <Modal
        title="Usuário não encontrado"
        visible={inviteViaEmailAppModal}
        footer={[
          <Button
            key="submit"
            type="primary"
            className="button-confirm"
            onClick={() => {
              setInviteViaEmailAppModal(false);
            }}
          >
            Ok
          </Button>,
        ]}
        confirmLoading={loading}
        onCancel={() => setInviteViaEmailAppModal(false)}
      >
        <div>
          O E-mail que você digitou não foi encontrado em nosso sistema.
          <br />
          <br />
        </div>
      </Modal>
      <Modal
        visible={confirmAddFriendModal}
        title="Você tem certeza que deseja adicionar essa conexão?"
        onCancel={() => setConfirmAddFriendModal(false)}
        footer={[
          <Button key="back" onClick={() => setConfirmAddFriendModal(false)}>
            Não
          </Button>,
          <Button
            key="submit"
            type="primary"
            className="button-confirm"
            onClick={() => {
              if (friendData) {
                onFinish(userId, friendData.id);
              }
              setConfirmAddFriendModal(false);
              setAddNewFriendModal(false);
              setGetEmailOrPhone('');
            }}
          >
            Sim
          </Button>,
        ]}
      >
        {friendData && (
          <List.Item.Meta
            title={<span className="list-item-title">Nome: {friendData.name || 'Esperando'}</span>}
            description={
              <span>
                E-mail: {friendData.email}
                <br />
                Telefone: {friendData.phone}
              </span>
            }
          />
        )}
      </Modal>
    </div >
  );
};

export default ManageFriends;
